import React from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap';

export default function Navigation() {
  return (
    <Navbar variant="dark" bg="dark" expand="md" className="p-2">
        <Navbar.Brand href="/">ResourcePlus</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                <Nav className="mr-auto">
                    <Link to="/resources" className="nav-link">Resources</Link>
                    <Link to="/categories" className="nav-link">Categories</Link>
                    <Link to="/bootstrap" className="nav-link">Bootstrap</Link>
                </Nav>                
            </Navbar.Text>
        </Navbar.Collapse>
    </Navbar>
  );
}
