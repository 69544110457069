import './App.css';
import Bootstrap from './components/Bootstrap/Bootstrap';
import Resources from './components/Resources/Resources';
import Navigation from './components/Navigation';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Categories from './components/Categories/Categories';
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Bootstrap/>}/>
          <Route path="/resources" element={<Resources/>}/>
          <Route path="/categories" element={<Categories/>}/>
          <Route path="/bootstrap" element={<Bootstrap/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
